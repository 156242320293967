<template>
  <plc-page :slide="false" :pic-url="banner">
    <div class="fixed-width page-content page-product">
      <div class="submenu-wrapper">
        <div @click="changeMenu(index)" v-for="(item, index) in menu" :key="index" class="item item-4" :class="{active: item.active}">
          <div class="submenu-name" v-html="item.name"></div>
          <img v-if="item.active" class="submenu-arrow" src="../assets/images/arrow-down-white.png">
          <img v-else class="submenu-arrow" src="../assets/images/arrow-down-black.png">
        </div>
      </div>
      <div class="content" v-if="currentMenu === 0">
        <div v-for="item in PRODUCT_AI_WEIGHT" :key="item.name" class="product-wrapper">
          <div class="pic-wrapper">
            <img class="pic-ai pic-hover" :src="item.picUrl">
          </div>
          <div class="pic-name">{{item.name}}</div>
        </div>
      </div>
      <div class="content" v-else-if="currentMenu === 1">
        <div v-for="item in PRODUCT_AI_TEMP" :key="item.name" class="product-wrapper">
          <div class="pic-wrapper">
            <img class="pic-ai pic-hover" :src="item.picUrl">
          </div>
          <div class="pic-name">{{item.name}}</div>
        </div>
      </div>
      <div class="content" v-else-if="currentMenu === 2">
        <div v-for="item in PRODUCT_MONITOR" :key="item.name" class="product-wrapper">
          <div class="pic-wrapper">
            <img class=" pic-hover" :class="{'vertical': item.vartical, 'horizontal': !item.vartical}" :src="item.picUrl">
          </div>
          <div class="pic-name">{{item.name}}</div>
        </div>
      </div>
      <div class="content" v-else-if="currentMenu === 3">
        <div v-for="item in PRODUCT_ENVIRONMENT" :key="item.name" class="product-wrapper">
          <div class="pic-wrapper">
            <img class=" pic-hover" :class="{'vertical': item.vartical, 'horizontal': !item.vartical}" :src="item.picUrl">
          </div>
          <div class="pic-name">{{item.name}}</div>
        </div>
      </div>
      <div class="content" v-else>
        <div v-for="item in PRODUCT_FEED" :key="item.name" class="product-wrapper">
          <div class="pic-wrapper">
            <img class=" pic-hover" :class="{'vertical': item.vartical, 'horizontal': !item.vartical}" :src="item.picUrl">
          </div>
          <div class="pic-name">{{item.name}}</div>
        </div>
      </div>
    </div>
  </plc-page>
</template>

<script type="text/ecmascript-6">
  import PlcPage from '../components/page/Index'
  import { SOLUTIONS, PRODUCT_AI_WEIGHT, PRODUCT_AI_TEMP, PRODUCT_MONITOR, PRODUCT_ENVIRONMENT, PRODUCT_FEED } from '../util/constants'
  import { deepClone } from '../util/common'

  export default {
    name: 'Product',
    components: {
      PlcPage
    },
    data () {
      return {
        banner: require('../assets/images/banner/banner-product.jpg'),
        menu: deepClone(SOLUTIONS),
        PRODUCT_AI_WEIGHT,
        PRODUCT_AI_TEMP,
        PRODUCT_MONITOR,
        PRODUCT_ENVIRONMENT,
        PRODUCT_FEED,
        currentMenu: 0
      }
    },
    created () {
      const menu = Number(localStorage.getItem('menu'))
      if (!isNaN(menu)) {
        this.changeMenu(menu)
        localStorage.removeItem('menu')
      }
    },
    methods: {
      changeMenu(index) {
        this.menu.forEach((item, idx) => {
          item.active = false
          if (idx === index) {
            item.active = true
          }
        })
        this.currentMenu = index
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .page-product
    .content
      display flex
      flex-wrap wrap
      align-items center
      .product-wrapper
        width 380px
        box-sizing border-box
        margin-bottom 30px
        margin-right 30px
        background-color #fff
        &:hover
          cursor pointer
          box-shadow 0 0 20px 5px rgba(0, 0, 0, 0.3)
        &:nth-child(3n)
          margin-right 0
        .pic-wrapper
          height 400px
          display flex
          align-items center
          justify-content center
          .vertical
            height 350px
          .horizontal
            width 380px
          .pic-ai
            width 260px
          .pic-hover
            transition all 0.1s ease-in-out 0s
            &:hover
              transform scale(1.05)
        .pic-name
          font-family "PingFang SC Medium"
          display flex
          align-items center
          justify-content center
          line-height 1.3
          padding-bottom 30px
          font-size 20px
          font-weight 600
          text-align center
          color #141010
</style>
